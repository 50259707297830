<template>
  <div class="settings">
    <CTabs variant="pills" :active-tab="0">
      <CTab :title="i18n.t('Profile')">
        <Profile />
      </CTab>
      <CTab :title="i18n.t('Password reset')">
        <h3 class="text-main_black">{{ $t("Password reset") }}</h3>
        <CCol md="4" class="pl-0 pr-0 add-miners__wrap pt-3">
          <form action="">
            <p class="mb-2">
              <label>{{ $t("Valid password") }}</label>
              <CInput class="" v-model="formFields.password" type="password"/>
            </p>
            <p class="mb-2">
              <label>{{ $t("New password") }}</label>
              <CInput class="" v-model="formFields.new_password" type="password"/>
            </p>
            <p class="mb-2 pb-2">
              <label>{{ $t("Repeat new password") }}</label>
              <CInput class="" v-model="formFields.new_password2" type="password"/>
            </p>
            <p class="errors text-error_color" v-if="error">
              {{ error }}
            </p>
            <p class="success text-success" v-if="success">
              {{ success }}
            </p>
            <p class="text-center" v-if="loading">
              <CSpinner grow size="md" color="gray" />
            </p>
            <CButton class="button-blue text-uppercase w-50" v-else style="border-radius: 8px" @click="changePassword">
              {{ $t("reset") }}
            </CButton>
          </form>
        </CCol>
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import i18n from "../../i18n";
import Profile from "../Users/Profile";
import {mapActions} from "vuex";
export default {
  name: "Settings",
  components: { Profile },
  data() {
    return {
      i18n,
      error: null,
      loading: false,
      success: "",
      formFields: {
        password: "",
        new_password: "",
        new_password2: ""
      }
    };
  },
  setup() {},
  created() {
    this.curPage();
  },
  methods: {
    ...mapActions("miners",["CHANGE_PASSWORD"]),
    curPage() {
      this.$emit("curPage", "profile");
    },
    changePassword(){
      this.loading = true;
      if(this.formFields.new_password !== this.formFields.new_password2){
        this.error = "Password doesn't match";
        this.loading = false;
        return;
      }
      this.CHANGE_PASSWORD(this.formFields).then((res) => {
        this.loading = false;
        this.success = res.data.msg;
        this.error = null;
      }).catch(er => {
        this.error = er.response.data.msg;
        this.loading = false;
        this.success = null;
      })
    }
  },
};
</script>

<style scoped></style>
